import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction, useTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  MobileLogo,
  WebLogo,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const [languageSelected, setLanguageSelected] = useState("en");
  const { i18n } = useTranslation();
  const handleLanguageChange = () => {
    const language = languageSelected === "en" ? "es" : "en";
    i18n.changeLanguage(language);
    setLanguageSelected(language);
  };
  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <MobileLogo>
              <SvgIcon
                src="BlueLogoMobile.png"
                folder="png"
                width="auto"
                height="38px"
              />
            </MobileLogo>
            <WebLogo>
              <SvgIcon
                src="BlueLogoWeb1.png"
                folder="png"
                width="100%"
                height="auto"
              />
            </WebLogo>
          </LogoContainer>
          <div onClick={() => handleLanguageChange()}>
            <LanguageSwitchContainer>
              <LanguageSwitch>
                <SvgIcon
                  src={
                    languageSelected === "en"
                      ? "united-states.svg"
                      : "united-states-gray.svg"
                  }
                  aria-label="homepage"
                  width="30px"
                  height="30px"
                />
              </LanguageSwitch>
              <LanguageSwitch>
                <SvgIcon
                  src={
                    languageSelected === "es" ? "spain.svg" : "spain-gray.svg"
                  }
                  aria-label="homepage"
                  width="30px"
                  height="30px"
                />
              </LanguageSwitch>
            </LanguageSwitchContainer>
          </div>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
